import { css } from '@emotion/react';

import { toCssVariables } from './CSSVariables.utils';

export const getCssVariables = () => css`
  --color-black: #000;
  --color-white: #fff;
  --color-primary-5: #241e3d;
  --color-primary-20: #302a47;
  --color-primary-30: #413a5c;
  --color-primary-35: #544a79;
  --color-primary-40: #685b97;
  --color-primary-50: #7466a8;
  --color-primary-55: #7a6da7;
  --color-primary-60: #8578b2;
  --color-primary-70: #b1a8d3;
  --color-primary-80: #c5bede;
  --color-primary-90: #d7d3e8;
  --color-secondary-0: var(--color-black);
  --color-secondary-40: #1a1726;
  --color-secondary-50: #34323d;
  --color-secondary-60: #54535b;
  --color-secondary-70: #86848e;
  --color-secondary-80: #b4b2bb;
  --color-secondary-85: #c7c5cc;
  --color-secondary-90: #dad9de;
  --color-secondary-93: #e3e1e9;
  --color-secondary-95: #efeef3;
  --color-secondary-97: #f7f6fc;
  --color-secondary-98: #fafafd;
  --color-tertiary-30: #f5e400;
  --color-tertiary-40: #ffed00;
  --color-tertiary-50: #fff466;
  --color-tertiary-60: #fff9a6;
  --color-quaternary-5: #3c3a35;
  --color-quaternary-30: #d8cebc;
  --color-quaternary-40: #f0e6d4;
  --color-quaternary-50: #f3efe6;
  --color-positive-70: #c9edc2;
  --color-error-40: #d9001a;
  --color-neutral-60: #6a6a6a;
  --font-family-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  --font-family-primary: 'COCOGOOSE', sans-serif;
  --font-family-primary-light: 'Cocogoose Pro Light', sans-serif;
  --font-family-primary-semilight: 'Cocogoose Pro Semilight', sans-serif;
  --font-family-primary-bold: 'Cocogoose Pro Darkmode', sans-serif;
  --line-height-12: 0.75rem;
  --line-height-14: 0.875rem;
  --line-height-16: 1rem;
  --line-height-18: 1.125rem;
  --line-height-20: 1.25rem;
  --line-height-22: 1.375rem;
  --line-height-24: 1.5rem;
  --line-height-26: 1.625rem;
  --line-height-28: 1.75rem;
  --line-height-30: 1.875rem;
  --line-height-36: 2.25rem;
  --line-height-38: 2.375rem;
  --line-height-52: 3.25rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-22: 1.375rem;
  --font-size-28: 1.75rem;
  --font-size-32: 2rem;
  --font-size-42: 2.625rem;
  --font-size-52: 3.25rem;
  --spacing-2: 0.125rem;
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-48: 3rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-96: 6rem;
  --spacing-128: 8rem;
  --spacing-160: 10rem;
  --letter-spacing: -0.16%;
  --icon-size-s: var(--spacing-16);
  --icon-size-m: var(--spacing-24);
  --icon-size-l: var(--spacing-32);
  --font-weight-bold: 400;
  --font-link-s: var(--font-size-12) / var(--line-height-12)
    var(--font-family-primary-light);
  --font-link-l: var(--font-size-14) / var(--line-height-18)
    var(--font-family-primary-light);
  --font-link-xl: var(--font-size-16) / var(--line-height-24)
    var(--font-family-primary-light);
  --font-preamble-l: var(--font-size-20) / var(--line-height-26)
    var(--font-family-primary-semilight);
  --font-preamble-s: var(--font-size-16) / var(--line-height-22)
    var(--font-family-primary-semilight);
  --font-body-s-bold: var(--font-weight-bold) var(--font-size-12) /
    var(--line-height-16) var(--font-family-primary-bold);
  --font-body-s-light: var(--font-size-12) / var(--line-height-16)
    var(--font-family-primary-light);
  --font-body-m-bold: var(--font-weight-bold) var(--font-size-14) /
    var(--line-height-18) var(--font-family-primary-bold);
  --font-body-m-light: var(--font-size-14) / var(--line-height-18)
    var(--font-family-primary-light);
  --font-body-l-bold: var(--font-weight-bold) var(--font-size-16) /
    var(--line-height-24) var(--font-family-primary-bold);
  --font-body-l-light: var(--font-size-16) / var(--line-height-24)
    var(--font-family-primary-light);

  --font-body-xl-bold: var(--font-weight-bold) var(--font-size-16) /
    var(--line-height-24) var(--font-family-primary-bold);
  --font-body-xl-light: var(--font-size-18) / var(--line-height-30)
    var(--font-family-primary-light);
  --font-label-s-bold: var(--font-weight-bold) var(--font-size-12) /
    var(--line-height-12) var(--font-family-primary-bold);
  --font-label-s-light: var(--font-size-12) / var(--line-height-12)
    var(--font-family-primary-light);
  --font-label-l-bold: var(--font-weight-bold) var(--font-size-14) /
    var(--line-height-14) var(--font-family-primary-bold);

  --font-label-l-light: var(--font-size-14) / var(--line-height-14)
    var(--font-family-primary-light);
  --font-label-xl-bold: var(--font-weight-bold) var(--font-size-16) /
    var(--line-height-16) var(--font-family-primary-bold);
  --font-label-xl-light: var(--font-size-16) / var(--line-height-16)
    var(--font-family-primary-light);
  --font-label-xxl-bold: var(--font-weight-bold) var(--font-size-20) /
    var(--line-height-20) var(--font-family-primary-bold);
  --font-label-xxl-light: var(--font-size-20) / var(--line-height-20)
    var(--font-family-primary-light);
  --font-heading-5: var(--font-weight-bold) var(--font-size-18) /
    var(--line-height-24) var(--font-family-primary-bold);
  --font-heading-4: var(--font-weight-bold) var(--font-size-22) /
    var(--line-height-28) var(--font-family-primary-bold);
  --font-heading-3: var(--font-weight-bold) var(--font-size-28) /
    var(--line-height-36) var(--font-family-primary-bold);
  --font-heading-2: var(--font-weight-bold) var(--font-size-32) /
    var(--line-height-38) var(--font-family-primary-bold);
  --font-heading-1: var(--font-weight-bold) var(--font-size-42) /
    var(--line-height-52) var(--font-family-primary-bold);
  --font-heading-big: var(--font-weight-bold) var(--font-size-52) /
    var(--line-height-52) var(--font-family-primary-bold);
  --font-caption-l-light: var(--font-size-12) / var(--line-height-16)
    var(--font-family-primary-light);
  --font-caption-l-bold: var(--font-weight-bold) var(--font-size-12) /
    var(--line-height-16) var(--font-family-primary-bold);
  --border-radius-s: 0.25rem;
  --border-radius-m: 0.5rem;
  --border-radius-l: 0.75rem;
  --border-radius-xl: 1rem;
  --border-radius-xxl: 2rem;

  ${getThemableCssVariables()}
`;
/**
 * @description CSS properties for themable elements, following a specific naming convention.
 * @example
 * [system = sembo]
 * [domain = inspire]
 * [type = s(emantic)|p(rimitive)|c(omponent)]
 * [category = color|typography|etc.]
 * [property = background|text|border|etc.]
 * [variant = primary|secondary|tertiary|quaternary|etc.]
 * [modifier? = interactive|subtle]
 * [state? = active|hover|focus|disabled]
 * w-s-color-background-primary-subtle-active
 */
export const themableCSSProperties = {
  /**
   * @description Background color of the site.
   */
  semboInspireSColorBackgroundPrimary: 'var(--color-quaternary-50)',

  /**
   * @description Background color of things like creator banner.
   */
  semboInspireSColorBackgroundSecondary: 'var(--color-white)',

  /**
   * @description Background of interactive elements.
   */
  semboInspireSColorBackgroundSecondaryInteractive:
    'var(--color-quaternary-40)',

  /**
   * @description Background of interactive elements.
   */
  semboInspireSColorBackgroundPrimaryInteractive: 'var(--color-secondary-40)',

  /**
   * @description Hover on the primary background.
   */
  semboInspireSColorBackgroundPrimaryInteractiveHover:
    'var(--color-secondary-50)',

  /**
   * @description Colors for texts in interactive elements
   */
  semboInspireSColorTextPrimaryInteractive: 'var(--color-white)',

  /**
   * @description Background color of the site.
   */
  semboInspireSColorBackgroundImportant: 'var(--color-primary-55)',

  /**
   * @description Background color of the site.
   */
  semboInspireSColorBackgroundImportantInteractive: 'var(--color-primary-55)',

  /**
   * @description Background color of the site.
   */
  semboInspireSColorBackgroundImportantInteractiveHover:
    'var(--color-primary-40)',

  /**
   * @description Background of subtle elements.
   */
  semboInspireSColorBackgroundPrimarySubtle: 'transparent',

  /**
   * @description Colors for texts in interactive elements
   */
  semboInspireSColorTextPrimarySubtle: 'var(--color-secondary-40)',
} as const;

/** Why do we have a separate fn for themable?
 * Because we don't want to re-skin using old variables
 * In that case, we should try to create new, more scalable variables.
 */
export const getThemableCssVariables = () => css`
  ${toCssVariables(themableCSSProperties)};
`;

export const getFontFaces = () => css`
  @font-face {
    font-family: 'Coco Sharp Italic';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/b4c3e06718076c9a71e47952395cdca11f947e18.woff2/Coco-Sharp-Variable-Italic.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/662fa2a84238f4114bf1031cc2e791f6eb0a8900.woff/Coco-Sharp-Variable-Italic.woff')
        format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Coco Sharp';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/84b8b13d514c2ebb1f0bf8eaea8176d657fe31f4.woff2/Coco-Sharp-Variable-Roman.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/f091ffc438916ac5b8bcef71c14f41f57367df7d.woff/Coco-Sharp-Variable-Roman.woff')
        format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'COCOGOOSE';
    src: url('https://cdn.sanity.io/files/inntox6v/production/2ef4dd6ce8092146e0afae4e11ab55dbc481ad0a.otf/Cocogoose-Pro-Regular.otf')
      format('opentype');
  }

  @font-face {
    font-family: 'Cocogoose Pro Darkmode';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/cb0acd973f40aa4e72252f6e16cf1a6c6b9ee82b.woff2/CocogoosePro-Darkmode.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/ad8f81bae1e42af558a7b1e49fa5e7579dad8db1.woff/CocogoosePro-Darkmode.woff')
        format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/bdc647dbedd727ad2ca8c267e51d355949e79392.svg/CocogoosePro-Darkmode.svg')
        format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro Semilight';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/0fd426dd95f586f6fa51b810d9c4ca060d88f834.woff2/CocogoosePro-Semilight.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/0303fab577cc7c8f971f9fd31839d940fa01f0a2.woff/CocogoosePro-Semilight.woff')
        format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/522d4793d87cb2090f19ffeeef2b476df25c8466.svg/CocogoosePro-Semilight.svg')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro Light';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/f8a2e25e3698e50e3590f79ab0eaf8dddd28bbf7.woff2/CocogoosePro-Light.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/251d15dbecae5edb1cf8b985c34d88ddaa0aa5e0.woff/CocogoosePro-Light.woff')
        format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/537d48520a7e903511c43223d9e2aae0b0e994d6.svg/CocogoosePro-Light.svg')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro';
    src:
      url('https://cdn.sanity.io/files/inntox6v/production/3d236b80db73f13f4a20fa13762755ec34f10c84.woff2/CocogoosePro-Regular.woff2')
        format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/0970c760da86f4c9428770e3b6cc8b2a4b093f5e.woff/CocogoosePro-Regular.woff')
        format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/84a84da87920cc07a66183e0982a8dbb45280aa0.svg/CocogoosePro-Regular.svg')
        format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;
