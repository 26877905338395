import * as Sentry from '@sentry/nextjs';
import IntlMessageFormat from 'intl-messageformat';
import { useCallback, useRef } from 'react';
import { PortableTextBlock } from 'sanity';

import * as localization from '../config/lang/locales';
import { GetCopyResult } from '../data/sanity/types';
import { useTranslationContext } from '../providers/TranslationProvider';
import { isSSR } from '../utils/helpers';
import { getMarket, supportedMarkets } from '../utils/markets';

export const langs = new Map<string, string>(
  supportedMarkets.map((market) => [
    `${market.lang}-${market.countryISO}`,
    market.lang,
  ])
);

const mapLangByDomainOrBrowser = (): string => {
  const market = getMarket(window.location.host);
  const browserLang = window.navigator?.language;

  const relevantLang = market?.lang ?? browserLang;

  if (relevantLang in localization) {
    return relevantLang;
  }

  if (langs.has(relevantLang)) {
    return langs.get(relevantLang) as string;
  }

  return 'en';
};

const getLanguageToUse = (lang: Required<string | undefined>): string =>
  lang ?? (!isSSR() ? mapLangByDomainOrBrowser() : 'en');

const logMissingItem = (
  type: 'microcopy' | 'macrocopy',
  path: string,
  lang?: string
) => {
  const errorMessage = `Missing ${type} in CMS using current language "${lang}" in path "${path}"`;

  console.error(errorMessage);
  if (!isSSR()) {
    Sentry.captureException(new Error(errorMessage), {
      extra: {
        path,
        href: window.location.href,
      },
    });
  }
};

export const createTranslateFunction = (
  inputLang: Required<string | undefined>,
  microcopy: Required<GetCopyResult | undefined>,
  createOptions: {
    missingKeysCache?: React.MutableRefObject<{ [key: string]: boolean }>;
    hideKeyIfMissing?: boolean;
    onMissingMicrocopy?: (key: string) => void;
  } = {}
) => {
  // this is to reuse compiled formatters for keys
  // to avoid too high performance penalty
  const formatterCache: { [key: string]: IntlMessageFormat } = {};

  if (!createOptions?.missingKeysCache) {
    createOptions.missingKeysCache = {
      // if used server-side, we use a stubbed ref object to store missing keys
      current: {},
    };
  }

  const t = (
    path: string,
    dynamicVariables: Record<string, any> = {},
    options: {
      overrideLang?: string;
      hideKeyIfMissing?: boolean;
    } = {}
  ): string => {
    const lang = getLanguageToUse(options?.overrideLang ?? inputLang);
    const cmsAndLocalLocalizations: { [key: string]: string } = {
      ...((localization as any)?.[lang] ?? {}),
      // HACK: replacing {{ and }} with single { and } to avoid issues with intl-messageformat
      //       we should fix these values in Sanity microcopies in the future
      ...(microcopy
        ? Object.keys(microcopy).reduce(
            (coll, key) => {
              const item = microcopy[key];

              if (typeof item === 'string') {
                coll[key] = item.replace(/\{\{/g, '{').replace(/\}\}/g, '}');
              }

              return coll;
            },
            {} as { [key: string]: string }
          )
        : {}),
    };

    // check if key exists for translation (local or in CMS)
    if (!cmsAndLocalLocalizations[path]) {
      // this thing is just for reporting missing microcopy to Sentry
      if (
        !createOptions?.hideKeyIfMissing &&
        !options?.hideKeyIfMissing &&
        microcopy &&
        Object.keys(microcopy).length > 0 &&
        microcopy[path] === undefined &&
        !createOptions?.missingKeysCache?.current[path + '/' + lang]
      ) {
        if (createOptions?.missingKeysCache?.current) {
          createOptions.missingKeysCache.current[path + '/' + lang] = true;
        }

        if (createOptions?.onMissingMicrocopy) {
          createOptions.onMissingMicrocopy(path);
        }
        logMissingItem('microcopy', path, lang);
      }

      return options.hideKeyIfMissing || createOptions.hideKeyIfMissing
        ? ''
        : path;
    }

    try {
      if (!formatterCache[path]) {
        formatterCache[path] = new IntlMessageFormat(
          cmsAndLocalLocalizations[path]!,
          lang
        );
      }

      const translatedText = formatterCache[path]!.format(dynamicVariables);

      return translatedText;
    } catch (e) {
      console.error('Error while translating', e);
      return createOptions.hideKeyIfMissing ? '' : path;
    }
  };

  return t;
};

export function useTranslate(
  customContext?: ReturnType<typeof useTranslationContext>
) {
  const translationContext = useTranslationContext();
  const { lang, microcopy, macrocopy, microcopyKeys, macrocopyKeys } =
    customContext ? customContext : translationContext;
  const missingKeysCache = useRef<{ [key: string]: boolean }>({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const t = useCallback(
    microcopyKeys
      ? (key: string) => key
      : createTranslateFunction(lang, microcopy, {
          missingKeysCache,
          onMissingMicrocopy: translationContext?.onMissingMicrocopy,
        }),
    [lang, microcopy, microcopyKeys, translationContext?.onMissingMicrocopy]
  );

  const pt = useCallback(
    (key: string): PortableTextBlock => {
      if (macrocopyKeys) {
        return {
          _key: key,
          _type: 'block',
          children: [
            {
              _key: `${key}-0`,
              _type: 'span',
              marks: [],
              text: key,
            },
          ],
          markDefs: [],
          style: 'normal',
        };
      }

      if (!macrocopy || !macrocopy[key]) {
        if (translationContext?.onMissingMacrocopy) {
          translationContext.onMissingMacrocopy(key);
        }

        logMissingItem('macrocopy', key, lang);
        return {
          _type: 'block',
          _key: key,
          children: [{ _type: 'span', text: key }],
        };
      }

      return macrocopy[key] as PortableTextBlock;
    },
    [lang, macrocopy, macrocopyKeys]
  );

  return { t, pt, lang };
}
