import { getClientValidatedEnv } from '../config/client-env';
import { ThemableCSSPropertiesType } from '../styles/CSSVariables.types';
import { HostAirportCodeAndLabelMap } from './types';

export const EVENTS_MAX_LISTENERS = 50;

export const IMAGE_TEXT_DIVIDER_MEDIA_GROUP_ID =
  '8258681f-55b6-4d53-adc2-8034e656adfe';

export const FALLBACK_IMAGE = '/m/trip-fallback-image.jpg';

export const PLACES_FALLBACK_IMAGE = 'ba0dc496-efa5-427c-859d-eb18c362e875';
export const PLACES_FALLBACK_IMAGE_2 = '7e0d2c65-0b97-45e2-9814-cb744692db56';

export const GET_YELLOW_FLOWER = () =>
  getClientValidatedEnv().NEXT_PUBLIC_SANITY_DATASET === 'production'
    ? '9a7e2060-d562-4cb3-b3a9-b632f078abea'
    : '8f74a25e-51ef-4f02-a76f-3045d30bf3eb';

export const contentTypePathPrefixMap: Record<string, string> = {
  articleContent: 'article',
  campaignContent: 'c',
  tagContent: 'tag',
  tripContent: 'flyer',
};

export const COOKIE_AUTH_NAME = '83D6F85345D8459aB80D97E8B1BB90CE';

export const CUSTOMER_SERVICE_URL = 'https://support.sembo.com';

export const HOST_AIRPORTS_MAP: HostAirportCodeAndLabelMap = {
  'www.sembo.se': [
    { code: 'STO', label: 'Stockholm' },
    { code: 'CPH', label: 'Köpenhamn' },
    { code: 'GOT', label: 'Göteborg' },
    { code: 'MMX', label: 'Malmö' },
    { code: 'OSL', label: 'Oslo' },
    { code: 'LLA', label: 'Luleå' },
    { code: 'UME', label: 'Umeå' },
    { code: 'SFT', label: 'Skellefteå' },
    { code: 'VBY', label: 'Visby' },
    { code: 'OSD', label: 'Åre/Östersund' },
    { code: 'KRN', label: 'Kiruna' },
    { code: 'VXO', label: 'Växjö' },
    { code: 'LPI', label: 'Linköping' },
    { code: 'KLR', label: 'Kalmar' },
    { code: 'SDL', label: 'Sundsvall' },
    { code: 'NYO', label: 'Stockholm Skavsta' },
  ],
  'www.sembo.dk': [
    { code: 'CPH', label: 'København' },
    { code: 'BLL', label: 'Billund' },
    { code: 'AAL', label: 'Aalborg' },
    { code: 'HAM', label: 'Hamborg' },
    { code: 'AAR', label: 'Aarhus' },
    { code: 'MMX', label: 'Malmö' },
  ],
  'www.sembo.de': [
    { code: 'FRA', label: 'Frankfurt' },
    { code: 'MUC', label: 'München' },
    { code: 'BER', label: 'Berlin' },
    { code: 'DUS', label: 'Düsseldorf' },
    { code: 'HAM', label: 'Hamburg' },
    { code: 'CGN', label: 'Köln' },
    { code: 'ZRH', label: 'Zürich' },
    { code: 'BSL', label: 'Basel' },
    { code: 'HAJ', label: 'Hannover' },
    { code: 'STR', label: 'Stuttgart' },
    { code: 'BRE', label: 'Bremen' },
  ],
  'www.sembo.no': [
    { code: 'OSL', label: 'Oslo' },
    { code: 'SVG', label: 'Stavanger' },
    { code: 'BGO', label: 'Bergen' },
    { code: 'TRD', label: 'Trondheim' },
    { code: 'AES', label: 'Ålesund' },
    { code: 'TOS', label: 'Tromsø' },
    { code: 'KRS', label: 'Kristiansand' },
    { code: 'BOO', label: 'Bodø' },
    { code: 'HAU', label: 'Haugesund' },
    { code: 'EVE', label: 'Evenes' },
  ],
  'www.sembo.pl': [
    { code: 'WAW', label: 'Warszawa' },
    { code: 'KRK', label: 'Kraków' },
    { code: 'GDN', label: 'Gdańsk' },
    { code: 'KTW', label: 'Katowice' },
    { code: 'WRO', label: 'Wrocław' },
    { code: 'POZ', label: 'Poznań' },
  ],
  'www.sembo.com': [
    { code: 'NYC', label: 'New York, NY' },
    { code: 'LAX', label: 'Los Angeles, CA' },
    { code: 'SFO', label: 'San Francisco, CA' },
    { code: 'MIA', label: 'Miami, FL' },
    { code: 'ORD', label: 'Chicago, IL' },
    { code: 'DFW', label: 'Dallas, TX' },
    { code: 'DEN', label: 'Denver, CO' },
    { code: 'BOS', label: 'Boston, MA' },
    { code: 'ATL', label: 'Atlanta, GA' },
    { code: 'WAS', label: 'Washington, DC' },
    { code: 'SEA', label: 'Seattle, WA' },
  ],
  'www.sembo.com.au': [
    { code: 'SYD', label: 'Sydney' },
    { code: 'MEL', label: 'Melbourne' },
    { code: 'BNE', label: 'Brisbane' },
    { code: 'PER', label: 'Perth' },
    { code: 'ADL', label: 'Adelaide' },
    { code: 'CNS', label: 'Cairns' },
    { code: 'OOL', label: 'Gold Coast' },
  ],
  'www.sembo.ca': [
    { code: 'YTO', label: 'Toronto' },
    { code: 'YVR', label: 'Vancouver' },
    { code: 'YUL', label: 'Montreal' },
    { code: 'YYC', label: 'Calgary' },
    { code: 'YOW', label: 'Ottawa' },
    { code: 'YEG', label: 'Edmonton' },
    { code: 'YHZ', label: 'Halifax' },
    { code: 'YWG', label: 'Winnipeg' },
  ],
  'ch.sembo.com': [
    { code: 'ZRH', label: 'Zürich' },
    { code: 'GVA', label: 'Geneva' },
    { code: 'BSL', label: 'Basel' },
  ],
  'www.sembo.nz': [
    { code: 'AKL', label: 'Auckland' },
    { code: 'CHC', label: 'Christchurch' },
    { code: 'WLG', label: 'Wellington' },
    { code: 'ZQN', label: 'Queenstown' },
  ],
  'www.sembo.fi': [
    { code: 'HEL', label: 'Helsinki' },
    { code: 'TMP', label: 'Tampere' },
    { code: 'OUL', label: 'Oulu' },
    { code: 'RVN', label: 'Rovaniemi' },
  ],
  'www.sembo.at': [
    { code: 'VIE', label: 'Wien' },
    { code: 'SZG', label: 'Salzburg' },
    { code: 'MUC', label: 'München' },
    { code: 'INN', label: 'Innsbruck' },
  ],
  'www.sembo.hu': [{ code: 'BUD', label: 'Budapest' }],
  'www.sembo.ie': [
    { code: 'DUB', label: 'Dublin' },
    { code: 'SNN', label: 'Shannon' },
    { code: 'ORK', label: 'Cork' },
    { code: 'BFS', label: 'Belfast' },
    { code: 'LON', label: 'London' },
    { code: 'AMS', label: 'Amsterdam' },
    { code: 'STO', label: 'Stockholm' },
    { code: 'FRA', label: 'Frankfurt' },
  ],
  'www.sembo.nl': [
    { code: 'AMS', label: 'Amsterdam' },
    { code: 'EIN', label: 'Eindhoven' },
    { code: 'DUS', label: 'Düsseldorf' },
    { code: 'RTM', label: 'Rotterdam' },
    { code: 'BRU', label: 'Brussel' },
  ],
  'www.sembo.lt': [{ code: 'VNO', label: 'Vilnius' }],
  'www.sembo.si': [
    { code: 'LJU', label: 'Ljubljana' },
    { code: 'ZAG', label: 'Zagreb' },
  ],
  'www.sembo.fr': [
    { code: 'PAR', label: 'Paris' },
    { code: 'NCE', label: 'Nice' },
    { code: 'LYS', label: 'Lyon' },
    { code: 'GVA', label: 'Genève' },
    { code: 'BSL', label: 'Basel' },
    { code: 'TLS', label: 'Toulouse' },
    { code: 'MRS', label: 'Marseille' },
    { code: 'NTE', label: 'Nantes' },
    { code: 'MPL', label: 'Montpellier' },
  ],
  'www.sembo.es': [
    { code: 'BCN', label: 'Barcelona' },
    { code: 'MAD', label: 'Madrid' },
    { code: 'AGP', label: 'Malaga' },
    { code: 'PMI', label: 'Palma de Mallorca' },
    { code: 'ALC', label: 'Alicante' },
    { code: 'VLC', label: 'Valencia' },
    { code: 'SVQ', label: 'Sevilla' },
    { code: 'BIO', label: 'Bilbao' },
    { code: 'LPA', label: 'Las Palmas' },
    { code: 'TFS', label: 'Tenerife' },
  ],
  'www.sembo.co.uk': [
    { code: 'LON', label: 'London' },
    { code: 'MAN', label: 'Manchester' },
    { code: 'EDI', label: 'Edinburgh' },
    { code: 'BHX', label: 'Birmingham' },
    { code: 'BRS', label: 'Bristol' },
    { code: 'GLA', label: 'Glasgow' },
    { code: 'BFS', label: 'Belfast' },
    { code: 'DUB', label: 'Dublin' },
  ],
  'www.sembo.pt': [
    { code: 'LIS', label: 'Lisboa' },
    { code: 'OPO', label: 'Porto' },
    { code: 'FAO', label: 'Faro' },
    { code: 'FNC', label: 'Funchal' },
    { code: 'PDL', label: 'Ponta Delgada' },
  ],
};

export const NO_AIRPORT_VALUE = 'none';

export const PREFOOTER_POPULAR_PLACES_LIMIT = 15;
export const PREFOOTER_POPULAR_PLACES_PAGE = 0;

export const DEFAULT_WHITELABEL_THEMABLE_CSS_PROPERTIES: ThemableCSSPropertiesType =
  {
    semboInspireSColorBackgroundPrimary: '#f7f7f7',
    semboInspireSColorBackgroundSecondaryInteractive: '#e4e4e4',
    semboInspireSColorTextPrimaryInteractive: 'var(--color-white)',
    semboInspireSColorBackgroundPrimarySubtle: 'var(--color-white)',
  };

export const CONTENT_API_BASE_URL = 'https://content-hc.sembo.com';
