import joi from 'joi';

interface ClientEnvVariables {
  NEXT_PUBLIC_SANITY_DATASET: string;
  NEXT_PUBLIC_SANITY_WORKSPACE: string;
  NEXT_PUBLIC_SANITY_PREVIEW_TOKEN: string;
  NEXT_PUBLIC_SANITY_DEBUG_QUERIES: boolean;
  NEXT_PUBLIC_SANITY_DEBUG_ENCODED_QUERIES: boolean;
  NEXT_PUBLIC_STATSIG_ID_PROD: string;
  // only when env is used via Content Studio
  SANITY_STUDIO_DATASET: string;
  SANITY_STUDIO_WORKSPACE: string;
  SANITY_STUDIO_MANAGEMENT_TOKEN?: string;
  NEXT_PUBLIC_SHOULD_INDEX: boolean;
  NEXT_PUBLIC_PRODUCTION_ENV?: boolean;
  NEXT_PUBLIC_CREATORS_API_URL: string;
}

const objectSchema = {
  NEXT_PUBLIC_SANITY_DATASET: joi.string().required(),
  NEXT_PUBLIC_SANITY_WORKSPACE: joi.string().required(),
  NEXT_PUBLIC_SANITY_PREVIEW_TOKEN: joi.string(),
  NEXT_PUBLIC_SANITY_DEBUG_QUERIES: joi.boolean(),
  NEXT_PUBLIC_STATSIG_ID_PROD: joi.string(),
  NEXT_PUBLIC_SANITY_DEBUG_ENCODED_QUERIES: joi.boolean(),
  NEXT_PUBLIC_PRODUCTION_ENV: joi.boolean(),
  // only when env is used via Content Studio
  SANITY_STUDIO_DATASET: joi.string(),
  SANITY_STUDIO_WORKSPACE: joi.string(),
  SANITY_STUDIO_MANAGEMENT_TOKEN: joi.string(),
  NEXT_PUBLIC_SHOULD_INDEX: joi.boolean().default(false),
  // TODO: This should be required but it's not being set from content studio right now
  NEXT_PUBLIC_CREATORS_API_URL: joi
    .string()
    .default('https://creators-api.sembo.com'), //.required(),
};

// Next.js requires us to spell out every process.env variable for them to work (client-side only)
const variables = {
  NEXT_PUBLIC_SANITY_DATASET:
    (process.env as unknown as ClientEnvVariables).NEXT_PUBLIC_SANITY_DATASET ??
    // hacky way to fallback to another env variable if exists
    (process.env as unknown as ClientEnvVariables).SANITY_STUDIO_DATASET,
  NEXT_PUBLIC_SANITY_WORKSPACE:
    (process.env as unknown as ClientEnvVariables)
      .NEXT_PUBLIC_SANITY_WORKSPACE ??
    // hacky way to fallback to another env variable if exists
    (process.env as unknown as ClientEnvVariables).SANITY_STUDIO_WORKSPACE,
  NEXT_PUBLIC_SANITY_PREVIEW_TOKEN: (
    process.env as unknown as ClientEnvVariables
  ).NEXT_PUBLIC_SANITY_PREVIEW_TOKEN,
  NEXT_PUBLIC_SANITY_DEBUG_QUERIES: (
    process.env as unknown as ClientEnvVariables
  ).NEXT_PUBLIC_SANITY_DEBUG_QUERIES,
  NEXT_PUBLIC_STATSIG_ID_PROD: (process.env as unknown as ClientEnvVariables)
    .NEXT_PUBLIC_STATSIG_ID_PROD,
  NEXT_PUBLIC_SANITY_DEBUG_ENCODED_QUERIES: (
    process.env as unknown as ClientEnvVariables
  ).NEXT_PUBLIC_SANITY_DEBUG_ENCODED_QUERIES,
  SANITY_STUDIO_MANAGEMENT_TOKEN: (process.env as unknown as ClientEnvVariables)
    .SANITY_STUDIO_MANAGEMENT_TOKEN,
  NEXT_PUBLIC_SHOULD_INDEX: (process.env as unknown as ClientEnvVariables)
    .NEXT_PUBLIC_SHOULD_INDEX,
  NEXT_PUBLIC_PRODUCTION_ENV: (process.env as unknown as ClientEnvVariables)
    .NEXT_PUBLIC_PRODUCTION_ENV,
  NEXT_PUBLIC_CREATORS_API_URL: (process.env as unknown as ClientEnvVariables)
    .NEXT_PUBLIC_CREATORS_API_URL,
};

const validationSchema = joi
  .object<ClientEnvVariables>(objectSchema)
  .unknown(true);

// Because Next.js requires us to spell out the properties
export const getClientValidatedEnv = () => {
  const validation = validationSchema.validate(variables);
  if (validation.error) {
    // eslint-disable-next-line no-console
    console.error(validation.error.details);

    throw new Error(validation.error.message);
  }
  return validation.value;
};
