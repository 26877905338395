import {
  HotelIngredientResponseQl,
  IngredientQl,
  InputIngredientTypeQl,
  InputMaybe,
  InputPaxGroupQl,
  ItineraryResponseQl,
  RecipeQl,
  TransportType,
} from '../smart-search/types';
import {
  LocalizedRecipe,
  ProductData,
  RecipeData,
  RecipeQlWithIngredients,
} from './types';

export const getActivities = (products: ProductData[]): ProductData[] =>
  products?.filter((p) => p.type === 'Ticket');

export function getFlightOrigin(
  recipe: LocalizedRecipe
): string | undefined | null {
  const flightOrigin = recipe.ingredients
    ?.filter((f) => f?.fromIata)
    .flatMap((f) => f?.fromIata);
  return flightOrigin ? flightOrigin[0] : undefined;
}

const getNumberOfAdults = (recipe: RecipeQl): number =>
  recipe.pax?.reduce((acc: number, room: InputMaybe<InputPaxGroupQl>) => {
    return acc + (room?.numberOfAdults ?? 0);
  }, 0) ?? 0;

const getNumberOfChildren = (recipe: RecipeQl): number =>
  recipe.pax?.reduce((acc: number, room: InputMaybe<InputPaxGroupQl>) => {
    return acc + (room?.childAges?.length ?? 0);
  }, 0) ?? 0;

export const getPricePerPerson = (
  recipe?: RecipeQl,
  itinerary?: ItineraryResponseQl | null
): number | undefined => {
  if (!itinerary?.totalPrice || !recipe) {
    return undefined;
  }

  const numberOfPeople =
    getNumberOfAdults(recipe) + getNumberOfChildren(recipe);

  return itinerary.totalPrice.amount / numberOfPeople;
};

export function getDestinationNames(recipe: RecipeQl): string[] {
  return (
    recipe.ingredients
      ?.filter((ingredient) => ingredient?.destination)
      .flatMap((ingredient) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getCityName(ingredient!.destination as string)
      ) ?? []
  );
}

export function getDestinationNamesForFlyer(
  itinerary: ItineraryResponseQl,
  productData: ProductData[] | undefined
): string[] {
  if (!itinerary?.result) {
    return [];
  }

  return itinerary.result
    .map((i) => i as HotelIngredientResponseQl)
    .filter((ingredient) => ingredient?.suggested?.hotelCode)
    .map(
      (ingredient) =>
        productData?.find(
          (product) =>
            product?.code?.toLowerCase() ===
            ingredient?.suggested?.hotelCode.toLowerCase()
        )?.city ?? ''
    );
}

export const getCityName = (destination: string): string => {
  if (destination.includes(',')) {
    const [firstPart] = destination.split(',');
    if (firstPart) {
      return firstPart.trim();
    }
  }

  const [firstPart] = destination.split('ST-');
  if (firstPart) {
    return firstPart.trim();
  }

  return '';
};

export const isFlightIngredient = (ingredient: InputMaybe<IngredientQl>) =>
  ingredient?.ingredientType?.toLowerCase() ===
    InputIngredientTypeQl.Flight.toLowerCase() ||
  !!ingredient?.transportTypes?.some(
    (ingredient) =>
      ingredient?.toLowerCase() === TransportType.Flight.toLowerCase()
  );

export const isFerryIngredient = (
  ingredient: InputMaybe<IngredientQl>
): boolean =>
  ingredient?.ingredientType?.toLowerCase() ===
    InputIngredientTypeQl.Ferry.toLowerCase() ||
  !!ingredient?.transportTypes?.some(
    (t) => t?.toLowerCase() === TransportType.Ferry.toLowerCase()
  );

export const isFerryRoundTripRecipe = (
  recipe?: RecipeQl | undefined
): boolean => {
  if (
    !recipe?.ingredients ||
    recipe.ingredients.length <= 1 ||
    !recipe.ingredients[0]
  ) {
    return false;
  }

  const lastIngredient = recipe.ingredients[recipe.ingredients.length - 1];
  if (!lastIngredient) {
    return false;
  }

  return (
    isFerryIngredient(recipe.ingredients[0]) &&
    isFerryIngredient(lastIngredient)
  );
};

export const isTransportIngredient = (ingredient: InputMaybe<IngredientQl>) =>
  ingredient?.ingredientType?.toLowerCase() ===
  InputIngredientTypeQl.Transport.toLowerCase();

export const isOneWayTransportTripRecipe = (
  recipe?: RecipeQl | undefined
): boolean => {
  if (!recipe?.ingredients || recipe.ingredients.length <= 1) {
    return false;
  }

  const firstIngredientIsTransport = isTransportIngredient(
    recipe.ingredients[0]!
  );
  const lastIngredientIsTransport = isTransportIngredient(
    recipe.ingredients.slice(-1)[0]!
  );

  return (
    (firstIngredientIsTransport && !lastIngredientIsTransport) ||
    (!firstIngredientIsTransport && lastIngredientIsTransport)
  );
};

export const isFlightRoundTripRecipe = (
  recipe?: RecipeQl | undefined
): recipe is RecipeQlWithIngredients => {
  if (!recipe?.ingredients || recipe.ingredients.length <= 1) {
    return false;
  }

  return (
    isFlightIngredient(recipe.ingredients[0]!) &&
    isFlightIngredient(recipe.ingredients[recipe.ingredients.length - 1]!)
  );
};

export const isHotelSuggestionIngredient = (
  ingredient: InputMaybe<IngredientQl>
) =>
  ingredient?.ingredientType?.toLowerCase() ===
    InputIngredientTypeQl.Hotel.toLowerCase() && !ingredient.code;

/** There is legislation that says we cannot call trips "package trips", but we still want to know if a trip
 * is similar to a package trip. This function checks if a trip is similar to a package trip. */
export const isSimilarToPackageTrip = (recipe: RecipeQl) => {
  const hasTransport = !!recipe.ingredients?.some(isTransportIngredient);
  const hasHotel = !!recipe.ingredients?.some(isHotelIngredient);
  const hasActivity = hasActivityIngredient(recipe);

  return hasHotel && (hasTransport || hasActivity);
};

/** Checks if ingredient is a hotel, which may be populated with specific hotel code, destination, polygonId, or other... */
export const isHotelIngredient = (
  ingredient: InputMaybe<IngredientQl>
): ingredient is IngredientQl =>
  ingredient?.ingredientType?.toLowerCase() ===
  InputIngredientTypeQl.Hotel.toLowerCase();

/** Checks if ingredient is pointing towards a specific hotel */
export const isHotelCodeIngredient = (
  ingredient: InputMaybe<IngredientQl>
): ingredient is IngredientQl & { hotelCodes: string[] } =>
  ingredient?.ingredientType?.toLowerCase() ===
    InputIngredientTypeQl.Hotel.toLowerCase() && !!ingredient?.hotelCodes?.[0];

/** Checks if recipe contains a single hotel ingredient, whether it is has specific hotel or not */
export const isSingleHotelRecipe = (
  recipe?: RecipeQl
): recipe is RecipeQlWithIngredients =>
  recipe?.ingredients?.length === 1 &&
  isHotelIngredient(recipe.ingredients[0]!);

export const isSingleHotelCodeRecipe = (recipe?: RecipeQl) =>
  recipe?.ingredients?.length === 1 &&
  isHotelCodeIngredient(recipe.ingredients[0]!);

export const isActivityIngredient = (ingredient: InputMaybe<IngredientQl>) =>
  ingredient?.ingredientType?.toLowerCase() ===
  InputIngredientTypeQl.Ticket.toLowerCase();

export const hasActivityIngredient = (
  recipeOrIngredient: RecipeQl | InputMaybe<IngredientQl>
) => {
  if (!recipeOrIngredient) {
    return false;
  }

  if ('ingredients' in recipeOrIngredient && recipeOrIngredient.ingredients) {
    return recipeOrIngredient.ingredients.some((ingredient) =>
      ingredient?.subIngredients?.some(isActivityIngredient)
    );
  } else if (
    'subIngredients' in recipeOrIngredient &&
    recipeOrIngredient.subIngredients
  ) {
    return recipeOrIngredient.subIngredients.some(isActivityIngredient);
  }
  return false;
};

const getNumberOfNightsFromRecipe = (recipe: RecipeQl): number | undefined =>
  recipe.ingredients?.reduce(
    (acc: number | undefined, i: InputMaybe<IngredientQl>) => {
      if (i?.numberOfNights) {
        acc = acc ?? 0;
        return acc + i?.numberOfNights;
      }
      return acc;
    },
    undefined
  );

export const getNumberOfNightsFromItinerary = (
  itinerary?: ItineraryResponseQl | null
): number =>
  itinerary?.result?.reduce((acc: number, value) => {
    if ((value as any)?.lengthOfStay?.numberOfNights) {
      return acc + (value as any)?.lengthOfStay?.numberOfNights;
    }
    return acc;
  }, 0) ?? 0;

export const getNumberOfNightsFromRecipeOrItinerary = (
  recipe: RecipeQl,
  itinerary?: ItineraryResponseQl | null
) =>
  getNumberOfNightsFromRecipe(recipe) ??
  getNumberOfNightsFromItinerary(itinerary);

export const getRecipeData = (recipe: RecipeQl): RecipeData => {
  const totalNights = getNumberOfNightsFromRecipe(recipe);
  const numberOfAdults = getNumberOfAdults(recipe);
  const numberOfChildren = getNumberOfChildren(recipe);

  const destinations = getDestinationNames(recipe);

  const activities =
    recipe.ingredients
      ?.filter(
        (i) =>
          i?.ingredientType?.toLowerCase() ===
          InputIngredientTypeQl.Ticket.toLowerCase()
      )
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .flatMap((i) => i!.destination!) ?? []; //ToDo: get activity name

  const isMultiStop =
    (recipe.ingredients?.filter(isHotelIngredient)?.length ?? 0) > 1;

  const hasFlight = !!recipe.ingredients?.some(isFlightIngredient);
  const hasHotel = !!recipe.ingredients?.some(isHotelIngredient);
  const hasFerry = !!recipe.ingredients?.some(isFerryIngredient);
  const hasActivity = !!hasActivityIngredient(recipe);

  return {
    totalNights,
    numberOfAdults,
    numberOfChildren,
    destinations,
    activities,
    isMultiStop,
    hasFlight,
    hasHotel,
    hasFerry,
    hasActivity,
    ...recipe,
  };
};

export const generateRecipeTitle = (recipe: RecipeQl | undefined): string => {
  if (!recipe) return '';
  const firstHotel = recipe?.ingredients?.find(
    (i: InputMaybe<IngredientQl>) =>
      (i?.ingredientType as string).toUpperCase() === 'HOTEL'
  );
  if (!firstHotel) return '';
  if (firstHotel.destination) return firstHotel.destination;

  return '';
};

type Translation = { single: string; plural: string };

type RecipeDescriptionTranslations = PaxTextTranslations & {
  night: Translation;
};

type PaxTextTranslations = {
  adult: Translation;
  child: Translation;
};

export const getRecipeDescription = (
  recipeData: RecipeData,
  t: RecipeDescriptionTranslations
): string | undefined => {
  const { totalNights, numberOfAdults, numberOfChildren } = recipeData;
  return `${totalNights} ${
    (totalNights ?? 0) > 1 ? t.night.plural : t.night.single
  }, ${getPaxText(numberOfAdults, numberOfChildren, t)}`;
};

export const getPaxText = (
  numberOfAdults: number,
  numberOfChildren: number,
  t: PaxTextTranslations
): string | undefined => {
  return `${getTextForQuantity(numberOfAdults, t.adult)} ${getTextForQuantity(
    numberOfChildren,
    t.child
  )}`;
};

export const getTextForQuantity = (
  quantity: number,
  t: Translation
): string => {
  return quantity
    ? `${quantity}\u00A0${quantity > 1 ? t.plural : t.single}`
    : '';
};

const internalMarkets = [
  'www.sembo.se',
  'www.sembo.dk',
  'www.sembo.no',
  'www.sembo.fi',
  'www.sembo.de',
  'www.sembo.co.uk',
  'www.sembo.at',
  'www.sembo.pl',
  'www.sembo.nl',
  'www.sembo.hu',
  'www.sembo.es',
  'www.sembo.com',
  'www.sembo.ie',
  'www.sembo.ca',
  'www.sembo.co.za',
  'www.sembo.sg',
  'www.sembo.com.au',
  'www.sembo.nz',
  'ch.sembo.com',
  'www.sembo.pt',
];
const gaParameter = 'utm_medium=widget';

export const addGAParameter = (url: string) => {
  const domain: string = new URL(url).hostname;
  const delimiter: string = url.includes('?') ? '&' : '?';

  if (internalMarkets.includes(domain)) {
    return url;
  } else {
    return `${url}${delimiter}${gaParameter}`;
  }
};
